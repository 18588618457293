<template>
  <CommonLayout>
    <div class="cabinet">
      <div class="cabinet__inner">
        <button
          class="button-default button-back uni-back"
          @click="$router.go(-1)"
        >
          <span></span>Назад
        </button>
        <div class="cabinet__head">
          <!-- <router-link
            class="button-default button-back cabinet__back"
            :to="{ name: 'rating', params: { type: 'team' } }"
            ><span></span>Назад</router-link
          > -->
          <div class="title">Рейтинг игроков по GPB-coins</div>
        </div>
        <div class="cabinet__body cabinet__body_type_rationg">
          <div class="cabinet__content">
            <div class="uni-multi-switcher">
              <router-link
                :to="{ name: 'usersCoins' }"
                class="uni-multi-switcher__button"
                >GPB-coins</router-link
              >
              <router-link
                :to="{ name: 'usersBattles' }"
                class="uni-multi-switcher__button"
              >
                Победы в поединке
              </router-link>
            </div>
            <div class="uni-search">
              <input
                type="text"
                v-model="searchQuery"
                placeholder="Поиск по ФИО"
              />
            </div>
            <div class="cabinet-table">
              <Loading v-if="loading" position="center" />
              <div v-else class="cabinet-table__inner">
                <table v-if="filteredListUser.length === 0">
                  <tbody>
                    <!-- <tr class="disabled empty">
                      <td>Пусто</td>
                    </tr> -->
                  </tbody>
                </table>
                <table v-else>
                  <thead>
                    <tr>
                      <th>ФИО</th>
                      <!-- <th width="140px">Достижения</th> -->
                      <th width="240px">
                        <div
                          class="uni-switcher uni-switcher_type_mini cabinet-table-switcher"
                          :class="{ active: isOverall }"
                        >
                          <button
                            class="uni-switcher__button"
                            @click="isOverall = !isOverall"
                          >
                            За игру
                          </button>
                          <button
                            class="uni-switcher__button"
                            @click="isOverall = !isOverall"
                          >
                            На планете
                          </button>
                          <Tooltip
                            class="tooltip_style_dark"
                            :msg="`
                              <table>
                                <tr><td>За игру</td><td>Количество GPB-coins, полученных за <br/>все время</td></tr>
                                <tr><td>На планете</td><td>Отображаются GPB-coins, полученные на <br/>текущей планете. Рейтинг обновляется <br/>еженедельно, данные при переходе на <br/>новую планету будут доступны со второй <br/>недели</td></tr>
                              </table>
                            `"
                          />
                        </div>
                      </th>
                      <th width="100px">Место</th>
                    </tr>
                  </thead>
                  <tbody>
                    <router-link
                      v-for="(item, index) in filteredListUser"
                      :key="index"
                      tag="tr"
                      :class="{ myself: item.id === getProfile.id }"
                      :to="linkToProfile(item.id)"
                    >
                      <td>{{ item.name }}</td>
                      <!-- <td>
                        <div v-if="item.achievements.length" class="uni-achive">
                          <router-link
                            tag="div"
                            :to="linkToProfile(item.id, 'achievements')"
                            v-for="(achieve, index) in item.achievements"
                            :key="index"
                            class="uni-achive__item"
                            :style="{
                              'background-image': `url('/static/achieves/${achieve.icon}')`,
                            }"
                          >
                            <Tooltip
                              type="rating"
                              :msg="`
                              <p><strong>${achieve.title}</strong></p>
                              <p>${achieve.description}</p>`"
                            />
                          </router-link>
                        </div>
                        <div v-else class="uni-achive-empty"></div>
                      </td> -->
                      <td>
                        <div class="uni-coins uni-coins_type_table">
                          <img
                            src="@/assets/img/pic2/coin.svg"
                            width="15"
                            height="15"
                            alt=""
                          />{{
                            isOverall
                              ? item.amount
                              : weekCoinsFilter(item.amount)
                          }}
                        </div>
                      </td>
                      <td>
                        {{ index + 1 }}
                      </td>
                    </router-link>
                  </tbody>
                </table>
                <div class="cabinet-table__more">
                  <button
                    v-if="!searchQuery && limit && limit < getUsersCoins.length"
                    @click="limit = null"
                  >
                    Смотреть все
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="cabinet__sidebar">
            <Dashboard view="ratingUsers" />
          </div>
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<script>
import Dashboard from "@/components/Dashboard.vue";
import Tooltip from "@/components/Tooltip.vue";
import CommonLayout from "@/layout/CommonLayout.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["teamID"],
  components: {
    CommonLayout,
    Dashboard,
    Tooltip,
  },
  data() {
    return {
      limit: 10,
      searchQuery: "",
      isOverall: true,
    };
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "getUsersCoinsStatus",
      "getUsersCoins",
      "getUsersCoinsOverall",
    ]),
    loading() {
      return this.getUsersCoinsStatus === "loading";
    },
    filteredListUser() {
      // const sorting = (a, b) => {
      //   if (this.isOverall) {
      //     return b.coins - a.coins;
      //   } else {
      //     return (
      //       b.calculatedCoinsCurrentIsland - a.calculatedCoinsCurrentIsland
      //     );
      //   }
      // };

      const list = this.isOverall
        ? this.getUsersCoinsOverall
        : this.getUsersCoins;

      if (this.searchQuery) {
        return (
          list
            .filter((item) => {
              return item.name
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase());
            })
            // .sort(sorting)
            .slice(0, this.limitSearch)
        );
      }

      return this.limit
        ? list
            .map((e) => e)
            // .sort(sorting)
            .slice(0, this.limit)
        : list;
    },
  },
  created() {
    if (this.getUsersCoinsStatus !== "success") {
      this.USERS_COINS_FETCH();
    }
  },
  methods: {
    ...mapActions(["USERS_COINS_FETCH"]),
    linkToProfile(userID, name) {
      if (name) {
        return { name: "achievements", params: { userID } };
      }
      return {
        name: "user",
        params: { userID: userID },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.cabinet-table {
  td,
  th {
    &:nth-child(3) {
      text-align: center;
    }
    &:nth-child(4) {
      text-align: center;
    }
  }
}

.myself {
  background-image: url("~@/assets/img/icon/star-white.svg");
  background-repeat: no-repeat;
  background-position: 7px calc(50% + 1px);
}
</style>
